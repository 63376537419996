import { inject } from "vue";
import { buildUrlPrefix } from "@shopware-pwa/helpers-next";
import { useShopwareContext, useSessionContext } from "#imports";

/*
 *   This composable is fixed useUrlResolver, since in resolveUrl function returning newUrl is wrong in their function.
 */
export function useFixedUrlResolver() {
  const { apiClient } = useShopwareContext();
  const { sessionContext } = useSessionContext();
  const nuxtApp = tryUseNuxtApp();

  const getUrlPrefix = () => {
    try {
      return inject("urlPrefix", "");
    } catch ($error) {
      return "";
    }
  };

  const resolveUrl = (url: string) => {
    // @see: https://codeql.github.com/codeql-query-help/javascript/js-polynomial-redos/
    if (url.length > 2083) {
      throw new Error("URL Input too long");
    }

    const navigationPattern = /[a-zA-Z0-9]+\/navigation\/[a-zA-Z0-9]+/;
    const productPattern = /[a-zA-Z0-9]+\/detail\/[a-zA-Z0-9]+/;
    const urlPrefix = getUrlPrefix();

    switch (true) {
      case productPattern.test(url):
      case navigationPattern.test(url):
        // Remove first lang segment of the URL
        const newUrl = url.split("/").slice(1);
        return newUrl ? buildUrlPrefix(newUrl.join("/"), urlPrefix) : url;
      default:
        return url;
    }
  };

  const resolveToSeoUrl = async (url: string) => {
    if (url.length > 2083) {
      throw new Error("URL Input too long");
    }

    const navigationPattern = /[a-zA-Z0-9]+\/navigation\/[a-zA-Z0-9]+/;
    const productPattern = /[a-zA-Z0-9]+\/detail\/[a-zA-Z0-9]+/;
    const urlPrefix = getUrlPrefix();

    switch (true) {
      case !!url.match(productPattern):
      case !!url.match(navigationPattern):
        const matchUrl =
          url.match(productPattern) ?? url.match(navigationPattern);
        if (!matchUrl) return url;

        if (nuxtApp?.payload?.data) {
          if (!nuxtApp?.payload?.data?.["fixedUrlResolver"]) {
            nuxtApp.payload.data["fixedUrlResolver"] = {};
          }
        }

        const newUrl = matchUrl[0].split("/").slice(1);
        const urlPath = buildUrlPrefix(newUrl.join("/"), urlPrefix).path;

        let seoResult;
        if (nuxtApp?.payload?.data?.["fixedUrlResolver"]?.[urlPath]) {
          seoResult = await Promise.resolve(
            nuxtApp.payload.data["fixedUrlResolver"][urlPath],
          );
        } else {
          seoResult = await apiClient.invoke("readSeoUrl post /seo-url", {
            filter: [
              {
                type: "contains",
                field: "pathInfo",
                value: urlPath,
              },
            ],
          });

          if (nuxtApp?.payload?.data) {
            nuxtApp.payload.data["fixedUrlResolver"][urlPath] = seoResult;
          }
        }

        const seoUrl = seoResult.elements[0]?.seoPathInfo;

        return seoUrl ? seoUrl : url;
      default:
        return url;
    }
  };

  return {
    resolveUrl,
    resolveToSeoUrl,
    getUrlPrefix,
  };
}
