import { ref } from "vue";
import { isInNuxtInstance } from "@/utils/isInNuxtInstance";
import { createSharedComposable } from "@vueuse/core";

export const usePageLoading = createSharedComposable(() => {
  const loadingInitValue = true;

  if (!isInNuxtInstance()) {
    return {
      isLoading: ref(loadingInitValue),
    };
  }
  const nuxtApp = tryUseNuxtApp();

  const isLoading = ref(loadingInitValue);
  if (!nuxtApp) {
    return {
      isLoading,
    };
  }

  nuxtApp.hook("page:start", () => {
    isLoading.value = true;
  });

  nuxtApp.hook("page:finish", () => {
    isLoading.value = false;
  });

  return {
    isLoading,
  };
});
