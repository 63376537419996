<template>
  <button
    :class="buttonClassNamesResolver"
    class="text-m-medium md:text-medium transition-colors"
    :disabled="disabled"
  >
    <slot />
  </button>
</template>

<script setup lang="ts">
import { computed } from "vue";

type ButtonVariant = "default" | "secondary";

const props = withDefaults(
  defineProps<{
    variant?: ButtonVariant | boolean;
    disabled?: boolean;
  }>(),
  {
    variant: "default",
    disabled: false,
  },
);

const buttonClassNamesResolver = computed(() => {
  const commonClassNamesForSecondaryVariant =
    "border bg-transparent py-[0.6875rem] md:py-[0.8125rem] px-[1.4375rem]";
  const commonClassNamesForDefaultVariant = "py-3 md:py-[0.875rem] px-6";
  switch (props.variant) {
    case "secondary":
      if (props.disabled) {
        return commonClassNamesForSecondaryVariant + " text-grey border-grey";
      }
      return (
        commonClassNamesForSecondaryVariant +
        " text-black border-black hover:bg-black hover:text-white hover:border-black/70"
      );
    case "default":
    default:
      if (props.disabled) {
        return commonClassNamesForDefaultVariant + " text-white bg-grey";
      }
      return (
        commonClassNamesForDefaultVariant +
        " text-white bg-black hover:bg-black/70"
      );
  }
});
</script>