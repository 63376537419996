import { defineStore } from "pinia";
import { ref } from "vue";
import type { Schemas } from "#shopware";

export const useActiveCategoryDetailStore = defineStore(
  "active-category-detail",
  () => {
    const activeCategoryDetail = ref<Schemas["Category"] | null>(null);

    return {
      activeCategoryDetail,
    };
  },
);
