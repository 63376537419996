export const useTrustedShops = createSharedComposable(() => {
  const { getTrustedShopsConfig } = useStoreApi();
  const { data: trustedShopsConfig } = useAsyncData(() => {
    return getTrustedShopsConfig();
  });

  const trustedShopsAttributes = computed(() => {
    const salesChannelRef =
      trustedShopsConfig?.value?.[0]?.data?.mappedChannels?.[0]
        ?.salesChannelRef;
    if (!salesChannelRef) return {};
    const salesChannelId = salesChannelRef.replace(/\.(en-GB|de-DE)/gi, "");
    if (!salesChannelId) return {};
    const id =
      trustedShopsConfig?.value?.[0]?.data?.[salesChannelId]?.["de-DE"]
        ?.trustbadgeConfiguration?.id;
    if (!id) return {};

    const attributes =
      trustedShopsConfig?.value?.[0]?.data?.[salesChannelId]?.["de-DE"]
        ?.trustbadgeConfiguration?.children?.[0]?.attributes;
    const toCorrectAttributesForm = Object.values(attributes).reduce(
      (acc: Record<string, PropertyKey>, objAttribute) => {
        return {
          ...acc,
          [objAttribute?.attributeName]: objAttribute?.value,
        };
      },
      {},
    );
    return toCorrectAttributesForm;
  });

  return {
    trustedShopsAttributes,
  };
});
