import { defineStore } from "pinia";
import { ref } from "vue";

// TODO: Combine headerDetails, logoBannerDetails and benefitBarDetails into one store later
export const useHeaderDetailsStore = defineStore("header-details", () => {
  const headerHeight = ref<number>(0);

  return {
    headerHeight,
  };
});
