export const greetingCardStates = {
  OVERVIEW: "overview",
  SELECT_CARD: "select-card",
  WRITE_MESSAGE: "write-message",
  CARD_ADDED: "card-added",
  SELECT_CARD_WITH_ADDED_CARD: "select-card-with-added-card",
  WRITE_MESSAGE_WITH_ADDED_CARD: "write-message-with-added-card",
} as const;

export const greetingCardMessageParts = {
  SALUTATION: "salutation",
  MESSAGE: "message",
  GREETING: "greeting",
  IS_BLANK: "isBlank",
} as const;
