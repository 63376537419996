export default defineNuxtPlugin(() => {
  const { sessionContext } = useSessionContext();
  watch(
    sessionContext,
    (session, prevSession) => {
      if (!session?.token || session?.token === prevSession?.token) return;
      shopwareExtensionsApi.contextTokenManager.set(session.token);
    },
    {
      immediate: true,
    },
  );
});
