export const responseErrorCodes = {
  NOT_FOUND: {
    code: 404,
    message: "Not Found",
  },
  SERVICE_UNAVAILABLE: {
    code: 503,
    message: "Service Unavailable",
  },
} as const;
