import { defineStore } from "pinia";
import { ref } from "vue";

export const useWishlistStore = defineStore("wishlist", () => {
  const wishlistItemsCount = ref(0);

  return {
    wishlistItemsCount,
  };
});
