export function getValueByPath(obj: Record<string, unknown>, path: string) {
  const keys = path.split(".");

  let value = obj;
  for (let key of keys) {
    value = value[key];
    if (value === undefined || value === null) {
      return undefined;
    }
  }
  return value;
}

export function setValueByPath(
  obj: Record<string, unknown>,
  path: string,
  value: any,
) {
  const keys = path.split(".");

  let current = obj;
  for (let i = 0; i < keys.length - 1; i++) {
    const key = keys[i];
    if (!current[key]) {
      current[key] = {};
    }
    current = current[key];
  }

  current[keys[keys.length - 1]] = value;
}
