import { storeApiEndpoints } from "@/constants/storeApiEndpoints";
import { createSharedComposable } from "@vueuse/core";
import type {
  ExtendedEntitySearchResult,
  SystemConfigBasicInformationResponse,
  SawadeEngineConfigResponse,
} from "@/types";
import type { Schemas } from "#shopware";
import type {
  SeoUrlRedirectResponseError,
  SeoUrlRedirectResponseSuccess,
} from "@/types";

export const useStoreApi = createSharedComposable(() => {
  const config = useRuntimeConfig();
  const baseUrl = `${config.public.shopware.shopwareEndpoint}`;
  const accessToken = config.public.shopware.shopwareAccessToken;
  const { sessionContext } = useSessionContext();
  const nuxtApp = tryUseNuxtApp();

  const getFullUrl = (endpoint: keyof typeof storeApiEndpoints) => {
    return baseUrl + storeApiEndpoints[endpoint];
  };

  const makeFetch = async <T>(
    url: string,
    options: RequestInit = {},
  ): Promise<T | null> => {
    const baseHeaders = {
      "Content-Type": "application/json",
      "sw-access-key": accessToken,
      "sw-context-token": sessionContext.value?.token || "",
    };

    if (nuxtApp?.payload?.data) {
      if (!nuxtApp?.payload?.data?.["storeApi"]) {
        nuxtApp.payload.data["storeApi"] = {};
      }

      if (nuxtApp?.payload?.data?.["storeApi"]?.[options.key]) {
        return Promise.resolve(nuxtApp.payload.data["storeApi"][options.key]);
      }
    }

    return $fetch(url, {
      ...options,
      headers: {
        ...baseHeaders,
        ...options.headers,
      },
    })
      .then((response) => {
        if (options.key && nuxtApp?.payload?.data) {
          nuxtApp.payload.data["storeApi"][options.key] = response;
        }
        return response as T;
      })
      .catch((err) => {
        console.error(err);
        return null;
      });
  };

  const getMediaByMediaIds = async (mediaIds: string[]) => {
    if (!mediaIds?.length) return Promise.resolve([]);

    const apiURL = getFullUrl("media");
    const requestKey = mediaIds.sort().join("|");

    const resp = makeFetch<Schemas["Media"][]>(apiURL, {
      method: "POST",
      body: {
        ids: mediaIds,
      } as any,
      key: requestKey,
    });
    return resp;
  };

  interface NavigationMenuOptions {
    depth?: number;
    activeId: string;
    rootId?: string;
  }

  /**
   * Abstract function to get navigation menu by using Store API [endpoint](https://shopware.stoplight.io/docs/store-api/87e95e72f7554-fetch-a-navigation-menu).
   * @param options.activeId - active category id or page navigation (main-navigation, service-navigation, footer-navigation)
   * @param options.rootId - root category id or page navigation (main-navigation, service-navigation, footer-navigation)
   */
  const getNavigationMenu = async (options: NavigationMenuOptions) => {
    const baseOptions = {
      depth: 1,
    };

    const safeRootId = options.rootId || options.activeId;
    const apiURL =
      getFullUrl("navigation") + `/${options.activeId}/${safeRootId}`;

    return makeFetch<Schemas["Category"][]>(apiURL, {
      method: "POST",
      body: {
        ...baseOptions,
        ...options,
      },
      headers: {
        "sw-include-seo-urls": true,
      },
      key: `${options.activeId}-${safeRootId}`,
    });
  };

  const getSeoUrls = async (foreignKeys: string[]) => {
    if (!foreignKeys?.length)
      return Promise.resolve({
        elements: [],
        total: 0,
      });

    const apiURL = getFullUrl("seoUrl");
    const stringifiedForeignKeys = foreignKeys.join("|");

    const sortedForeignKeys = foreignKeys.sort().join("|");

    const payload = {
      filter: [
        {
          type: "equalsAny",
          field: "foreignKey",
          value: stringifiedForeignKeys,
        },
        {
          type: "equals",
          field: "isCanonical",
          value: true,
        },
      ],
    };

    return makeFetch<ExtendedEntitySearchResult<Schemas["SeoUrl"][]>>(apiURL, {
      method: "post",
      body: payload,
      key: sortedForeignKeys,
    });
  };

  const getSystemConfigBasicInformation = async () => {
    const apiURL = getFullUrl("systemConfigBasicInformation");

    return makeFetch<SystemConfigBasicInformationResponse>(apiURL, {
      key: "systemConfigBasicInformation",
    });
  };

  const getSawadeEngineConfig = async () => {
    const apiURL = getFullUrl("sawadeEngineConfig");

    return makeFetch<SawadeEngineConfigResponse>(apiURL, {
      key: "sawadeEngineConfig",
    });
  };

  const getTrustedShopsConfig = async () => {
    const apiURL = getFullUrl("trustedShopsConfig");

    return makeFetch(apiURL, {
      key: "trustedShopsConfig",
    });
  };

  const getCategoriesByCmsPageIds = async (
    cmsPageIds: string[],
  ): Promise<
    Schemas["EntitySearchResult"] & { elements: Schemas["Category"][] }
  > => {
    if (!Array.isArray(cmsPageIds) || !cmsPageIds.length) {
      return Promise.resolve({
        elements: [],
        total: 0,
      });
    }

    const apiURL = getFullUrl("category");

    const stringifiedCmsPageIds = cmsPageIds.join("|");

    const payload = {
      filter: [
        {
          type: "equalsAny",
          field: "cmsPageId",
          value: stringifiedCmsPageIds,
        },
      ],
    };

    return makeFetch<
      Schemas["EntitySearchResult"] & { elements: Schemas["Category"][] }
    >(apiURL, {
      method: "POST",
      body: payload,
      headers: {
        "sw-include-seo-urls": true,
      },
    });
  };

  const getProductCrossSellings = (
    productId: string,
  ): Promise<Schemas["ProductCrossSelling"][]> => {
    if (!productId) return Promise.resolve([]);

    const apiURL = `${getFullUrl("product")}/${productId}/cross-selling`;

    const payload = {
      associations: {
        media: {},
      },
    };

    return makeFetch<Schemas["ProductCrossSelling"][]>(apiURL, {
      method: "post",
      body: payload,
      headers: {
        "sw-include-seo-urls": true,
      },
    });
  };

  const findRedirectForUrl = async (path: string) => {
    const apiURL = getFullUrl("findRedirect") + `?sourceUrl=${path}`;

    return makeFetch<
      SeoUrlRedirectResponseSuccess | SeoUrlRedirectResponseError
    >(apiURL);
  };

  return {
    getMediaByMediaIds,
    getNavigationMenu,
    getSeoUrls,
    getCategoriesByCmsPageIds,
    getSystemConfigBasicInformation,
    getProductCrossSellings,
    getSawadeEngineConfig,
    getTrustedShopsConfig,
    findRedirectForUrl,
  };
});
