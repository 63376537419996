import { defineStore } from "pinia";
import { ref, computed } from "vue";

export const useListingStore = defineStore("listing", () => {
  const sidebarSelectedFilters = ref<{ [key: string]: any }>({
    properties: [],
  });

  const selectedOptionIds = computed(() => [
    ...sidebarSelectedFilters.value.properties,
  ]);

  return {
    sidebarSelectedFilters,
    selectedOptionIds,
  };
});
