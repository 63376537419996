import type { Schemas, operations } from "#shopware";
import { useCartFunction as swUseCart } from "@shopware-pwa/composables-next";

type LineItemProductParams = {
  id: string;
  quantity: number;
  type: "product";
};

const _useCart = (): UseCartReturn & {
  addProductWithOptions: typeof addProductWithOptions;
  addProducts: typeof addProducts;
} => {
  const { apiClient } = useShopwareContext();
  const useCartData: UseCartReturn = swUseCart();

  const _storeCart = useContext<Schemas["Cart"]>("swCart");
  const _storeCartErrors = useContext<Schemas["Cart"]["errors"] | null>(
    "swCartErrors",
  );

  /**
   * Example on how to override the default `refreshCart` method
   *
   * @param {Schemas["Cart"]} newCart
   * @returns
   */
  async function refreshCart(
    newCart?: Schemas["Cart"],
  ): Promise<Schemas["Cart"]> {
    return useCartData.refreshCart(newCart);
  }

  const setCartErrors = (cart: Schemas["Cart"]) => {
    if (Object.keys(cart.errors || {}).length) {
      _storeCartErrors.value = Object.assign(
        _storeCartErrors.value ? _storeCartErrors.value : {},
        cart.errors,
      );
    }
  };

  async function addProductWithOptions(params: {
    id: string;
    quantity?: number;
    options?: Record<string, string | number>;
  }): Promise<Schemas["Cart"]> {
    const safeOptions = params.options || {};
    const addToCartResult = await apiClient.invoke(
      "addLineItem post /checkout/cart/line-item",
      {
        items: [
          {
            id: params.id,
            referencedId: params.id,
            quantity: params.quantity,
            type: "product",
            ...safeOptions,
          },
        ],
      },
    );
    _storeCart.value = addToCartResult;
    setCartErrors(addToCartResult);
    return addToCartResult;
  }

  async function addProducts(
    items: LineItemProductParams[],
  ): Promise<Schemas["Cart"]> {
    if (items.length <= 0) {
      return _storeCart.value;
    }

    if (items.length === 1) {
      return addProductWithOptions({
        id: items[0].id,
        quantity: items[0].quantity,
      });
    }
    const { data: addToCartResult } = await apiClient.invoke(
      "addLineItem post /checkout/cart/line-item",
      {
        items,
      },
    );

    return addToCartResult;
  }

  return {
    ...useCartData,
    refreshCart,
    addProductWithOptions,
    addProducts,
  };
};

export const useCart = createSharedComposable(_useCart);
