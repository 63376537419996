export const storeApiEndpoints = {
  media: "/media",
  navigation: "/navigation",
  seoUrl: "/seo-url",
  systemConfigBasicInformation: "/system-config?domain=core.basicInformation",
  sawadeEngineConfig: "/system-config?domain=CrehlerSawadeEngine.config",
  trustedShopsConfig: "/system-config?domain=trustedShopsApp.config",
  category: "/category",
  product: "/product",
  findRedirect: "/find-redirect",
} as const;
