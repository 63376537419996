import type { GreetingsCardLineItem } from "@/types";
import { greetingCardMessageParts } from "@/constants/greetingCardStates";
import { defineStore } from "pinia";

type GetCardsReturn = Awaited<
  ReturnType<
    typeof shopwareExtensionsApi.methods.crehler.greetingsCard.getCards
  >
>;

export const useCheckoutGreetingsCardStore = defineStore(
  "checkout-greetings-card",
  () => {
    const defaultCardDetails = {
      selectedCard: null,
      cardMessage: {
        draft: {
          [greetingCardMessageParts["GREETING"]]: "",
          [greetingCardMessageParts["MESSAGE"]]: "",
          [greetingCardMessageParts["SALUTATION"]]: "",
          [greetingCardMessageParts["IS_BLANK"]]: false,
        },
        submitted: {
          [greetingCardMessageParts["GREETING"]]: "",
          [greetingCardMessageParts["MESSAGE"]]: "",
          [greetingCardMessageParts["SALUTATION"]]: "",
          [greetingCardMessageParts["IS_BLANK"]]: false,
        },
      },
    } as const;

    const cardDetails = reactive<{
      selectedCard: GreetingsCardLineItem | null;
      cardMessage: {
        draft: {
          salutation: string;
          message: string;
          greeting: string;
          isBlank: boolean;
        };
        submitted: {
          salutation: string;
          message: string;
          greeting: string;
          isBlank: boolean;
        };
      };
    }>(JSON.parse(JSON.stringify(defaultCardDetails)));

    const clearCardDetails = () => {
      cardDetails.selectedCard = JSON.parse(
        JSON.stringify(defaultCardDetails.selectedCard),
      );
      cardDetails.cardMessage = JSON.parse(
        JSON.stringify(defaultCardDetails.cardMessage),
      );
    };

    const isCartLoading = ref(false);

    const nuxtApp = useNuxtApp();

    const { data, pending, execute } = useAsyncData<GetCardsReturn>(
      "greetings-cards",
      () => shopwareExtensionsApi.methods.crehler.greetingsCard.getCards(),
      {
        getCachedData: (key: string) => {
          return nuxtApp.payload.data[key];
        },
      },
    );

    return {
      cardDetails,
      clearCardDetails,
      isCartLoading,
      greetingsCards: data,
      greetingsCardsLoading: pending,
      fetchGreetingsCards: execute,
    };
  },
);
