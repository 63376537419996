export const benefitBarSnippetKeys = [
  "uspBar.firstLeftText",
  "uspBar.secondLeftText",
  "uspBar.thirdLeftText",
  "uspBar.fourthLeftText",
  "uspBar.fifthLeftText",
  "uspBar.sixthLeftText",
  "uspBar.seventhLeftText",
  "uspBar.eighthLeftText",
  "uspBar.ninthLeftText",
  "uspBar.tenthLeftText",
] as const;
