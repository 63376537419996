import { useUser as swUseUser } from "@shopware-pwa/composables-next";
import { createSharedComposable } from "@vueuse/core";

export const useUser = createSharedComposable(() => {
  const useUserData = swUseUser();

  // here you can experiment with overriding the default composable

  return {
    ...useUserData,
  };
});
