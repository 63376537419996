export const brandShopMarkerStyles = {
  width: "40px",
  height: "53.8px",
} as const;

export const otherShopMarkerStyles = {
  width: "32px",
  height: "43.1px",
} as const;

export const mapStyles = [
  {
    featureType: 'poi.business',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'poi.park',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'all',
    elementType: 'all',
    stylers: [{ saturation: -100 }],
  },
] as const;