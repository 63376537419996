import { watch, onUnmounted, onMounted } from "vue";

export const useInternalLinkHandler = () => {
  const linkSelector = "a";
  const links = shallowRef<Element[] | HTMLElement[]>([]);
  const router = useRouter();

  const config = useRuntimeConfig();

  const storefrontUrl = config.public.shopware.devStorefrontUrl;

  const handleInternalLinkClick = (event: MouseEvent) => {
    const $link = event.currentTarget as HTMLAnchorElement;
    const url = $link.getAttribute("href") || "";
    const isExternalLink = isExternalURL(url);
    const isSameDomainLink = isSameDomain(url);
    if (isExternalLink && !isSameDomainLink) return;

    const hasTargetBlank = $link.getAttribute("target") === "_blank";
    if (hasTargetBlank) return;

    const isDownloadLink = $link.hasAttribute("download");
    if (isDownloadLink) return;

    const extraLinks = ["tel:", "mailto:"];
    const isExtraLink = extraLinks.some((link) => url.startsWith(link));
    if (isExtraLink) return;

    let safeUrl = isSameDomainLink ? url.replace(`${storefrontUrl}/`, "") : url;

    event.preventDefault();
    router.push(safeUrl);
  };

  const clearLinkEvents = () => {
    if (links.value.length === 0) return;
    links.value.forEach((link) => {
      link.removeEventListener("click", handleInternalLinkClick);
    });
  };

  const safeUpdateLinks = () => {
    const newLinkCandidates = Array.from(
      document.body.querySelectorAll(linkSelector),
    );
    const areLinksEqual =
      newLinkCandidates.length === links.value.length &&
      newLinkCandidates.every((link, index) => link === links.value[index]);
    if (areLinksEqual) return;

    clearLinkEvents();
    links.value = newLinkCandidates;
  };

  let observer: MutationObserver;

  const initMutationObserver = () => {
    const config = { childList: true, subtree: true };

    observer = new MutationObserver(safeUpdateLinks);

    observer.observe(document.body, config);
  };

  watch(links, (newLinks) => {
    newLinks.forEach((link) => {
      link.addEventListener("click", handleInternalLinkClick);
    });
  });

  onUnmounted(() => {
    observer.disconnect();
    clearLinkEvents();
  });

  onMounted(() => {
    initMutationObserver();
  });
};
