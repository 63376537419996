import { defineStore } from "pinia";
import { ref } from "vue";

export const useCheckoutStore = defineStore("checkout-details", () => {
  const checkoutStep = ref(0);
  const isStepperVisible = ref(true);
  const promoCodes = ref([]);
  const isPromoCodeApplied = ref();
  const isLoading = reactive<{ [key: string]: boolean }>({});
  const creditCardDetails = ref({
    cardNumber: "",
    cardHolder: "",
    expiryDate: "",
    cvc: "",
  });
  const shippingMethodSelected = ref("");
  const paymentMethodSelected = ref("");
  const shippingMethodTechnicalName = ref("");
  const isDHLShippingMethod = ref(false);

  return {
    isStepperVisible,
    checkoutStep,
    promoCodes,
    isPromoCodeApplied,
    isLoading,
    creditCardDetails,
    shippingMethodSelected,
    paymentMethodSelected,
    shippingMethodTechnicalName,
    isDHLShippingMethod,
  };
});
