import { defineStore } from "pinia";
import { ref } from "vue";

export const useProductLabelsInCategory = defineStore(
  "product-labels-in-category",
  () => {
    const productLabels = ref({});

    return {
      productLabels,
    };
  },
);
