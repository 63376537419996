import { computed } from "vue";

/**
 * Safe i18n, since we're using libraries which are not connected to nuxt they don't have acces to the i18n function,
 * so we need to create a fallback function for it.
 */
export const useSafeI18n = () => {
  if (typeof useNuxtApp === "undefined") {
    return {
      t: (key: string) => "",
      locale: computed(() => ""),
    };
  }

  const { $i18n } = useNuxtApp();
  const t = $i18n.t;
  const locale = $i18n.locale;
  return {
    t,
    locale,
  };
};
