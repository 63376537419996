import { createSharedComposable } from "@vueuse/core";
import { isInNuxtInstance } from "@/utils/isInNuxtInstance";

export const useInternalLinkMapper = createSharedComposable(() => {
  if (!isInNuxtInstance()) {
    return {
      mapLink: (link: string) => Promise.resolve(link),
      mapHTMLWithLinks: (html: string) => Promise.resolve(html),
      mapArrayWithHTMLLinks: (array: any[], keyToValue?: string) =>
        Promise.resolve(array),
    };
  }

  const { resolveToSeoUrl } = useFixedUrlResolver();

  const mapLink = async (link: string) => {
    if (!link) return link;
    if (isExternalURL(link)) {
      return link;
    }

    const url = await resolveToSeoUrl(link);

    return url.startsWith("/") ? url : `/${url}`;
  };

  const mapHTMLWithLinks = async (html?: any) => {
    const htmlPromise = Promise.resolve(html);

    if (!html) return htmlPromise;
    if (typeof html !== "string") return htmlPromise;
    const links = html.match(/href="([^"]*)"/g);
    const linkValues = links?.map((link) => link.split('"')[1]);
    if (!Array.isArray(linkValues) || linkValues?.length === 0)
      return htmlPromise;

    const mappedLinkPromises = linkValues?.map(mapLink);
    const mappedLinks = (
      await Promise.allSettled(mappedLinkPromises || [])
    )?.map((result) => result?.value);

    const linksDictionary = linkValues.reduce((acc, link, index) => {
      acc[link] = mappedLinks[index];
      return acc;
    }, {});

    linkValues?.forEach((link) => {
      html = html.replace(link, linksDictionary[link] || link);
    });

    return html;
  };

  const mapArrayWithHTMLLinks = async (array: any[], keyToValue?: string) => {
    if (!Array.isArray(array)) return array;

    const deepClone = JSON.parse(JSON.stringify(array));

    const configItemReferences = [];
    const htmlPromises = [];

    array.forEach(async (item, itemIdx) => {
      if (!keyToValue) {
        configItemReferences.push(itemIdx);
        htmlPromises.push(mapHTMLWithLinks(item));
        return;
      }

      const htmlCandidate = getValueByPath(item, keyToValue);
      if (!htmlCandidate) return;

      configItemReferences.push(keyToValue);
      htmlPromises.push(mapHTMLWithLinks(htmlCandidate));
    });

    const mappedHTMLArray = (await Promise.allSettled(htmlPromises))?.map(
      (htmlPromise) => htmlPromise?.value,
    );

    configItemReferences.forEach((reference, index) => {
      if (typeof reference === "number") {
        deepClone[reference] = mappedHTMLArray[index];
      } else {
        setValueByPath(deepClone[index], reference, mappedHTMLArray[index]);
      }
    });

    return deepClone;
  };

  return {
    mapLink,
    mapHTMLWithLinks,
    mapArrayWithHTMLLinks,
  };
});
