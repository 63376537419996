import { useI18n } from 'vue-i18n';

export const useStoreLocatorUtils = () => {
  const { t } = useI18n();

  const formatAddress = (addressComponents) => {
    let street = null;
    let houseNumber = null;
    let city = null;
    let country = null;

    addressComponents.forEach((component) => {
      if (component.types.includes("route")) {
        street = component.long_name;
      } else if (component.types.includes("street_number")) {
        houseNumber = component.long_name;
      } else if (component.types.includes("locality")) {
        city = component.long_name;
      } else if (component.types.includes("country")) {
        country = component.long_name;
      }
    });

    let addressParts = [];
    if (street) {
      addressParts.push(street);
    }
    if (houseNumber) {
      addressParts.push(houseNumber);
    }
    if (city) {
      addressParts.push(city);
    }
    if (country) {
      addressParts.push(country);
    }

    return addressParts.join(", ");
  };

  const formatRange = (start, end, open, close, dayLabel) => {
    if (start === end) {
      return `${dayLabel[start]}: ${open} – ${close}`;
    } else {
      return `${dayLabel[start]} - ${dayLabel[end]}: ${open} – ${close}`;
    }
  };

  const formatOpeningHours = (openingHours) => {
    if (!openingHours) {
      return null;
    }

    const dayLabel = {
      1: t("storeLocator.monday"),
      2: t("storeLocator.tuesday"),
      3: t("storeLocator.wednesday"),
      4: t("storeLocator.thursday"),
      5: t("storeLocator.friday"),
      6: t("storeLocator.saturday"),
      7: t("storeLocator.sunday"),
    };

    const sortedHours = openingHours
      .filter((open) => open.day >= 1 && open.day <= 6)
      .sort((a, b) => a.day - b.day);

    let result = [];
    let lastRangeStart = null;
    let lastRangeEnd = null;
    let lastOpen = null;
    let lastClose = null;

    sortedHours.forEach((currentDay) => {
      const { day, open, close } = currentDay;

      if (lastOpen === open && lastClose === close) {
        lastRangeEnd = day;
      } else {
        if (lastRangeStart !== null) {
          result.push(
            formatRange(lastRangeStart, lastRangeEnd, lastOpen, lastClose, dayLabel)
          );
        }

        lastRangeStart = day;
        lastRangeEnd = day;
        lastOpen = open;
        lastClose = close;
      }
    });

    if (lastRangeStart !== null) {
      result.push(
        formatRange(lastRangeStart, lastRangeEnd, lastOpen, lastClose, dayLabel)
      );
    }

    return result;
  };

  const formatStoresData = (stores: any[]) => {
    return stores?.map((store) => {
      return {
        ...store,
        openingHoursText: formatOpeningHours(store.openingHours),
      };
    });
  };

  return {
    formatAddress,
    formatRange,
    formatOpeningHours,
    formatStoresData
  };
}
