import type { UseProductSearchReturn } from "@shopware-pwa/composables-next";
import { defineStore } from "pinia";
import { ref } from "vue";
import type { Schemas } from "#shopware";

export const useActiveProductDetailStore = defineStore(
  "active-product-detail",
  () => {
    const activeProductDetail = ref<Awaited<
      ReturnType<UseProductSearchReturn["search"]>
    > | null>(null);

    const crossSellings = ref<Schemas["CrossSellingElementCollection"] | null>(
      null,
    );

    return {
      activeProductDetail,
      crossSellings,
    };
  },
);
