import { defineStore } from "pinia";
import { ref } from "vue";
import { listingRenderTypes } from "@/constants/listing";

export const useProductListingStore = defineStore("product-listing", () => {
  const listingRenderType = ref<
    (typeof listingRenderTypes)[keyof typeof listingRenderTypes]
  >(listingRenderTypes["DISPLAY_CMS_BLOCKS"]);

  return {
    listingRenderType,
  };
});
