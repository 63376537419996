import { defineStore } from "pinia";
import { ref, computed } from "vue";

export const useLogoBannerDetailsStore = defineStore(
  "logo-banner-details",
  () => {
    const areSomeLogoBannersVisible = ref<boolean[]>([]);
    const logoBannersOnPage = ref<string[]>([]);

    const computedLogoBannerBackgroundColor = computed(() => {
      if (logoBannersOnPage.value.length === 0) return null;
      return logoBannersOnPage.value.at(-1);
    });

    const computedAreSomeLogoBannersVisible = computed(() => {
      return areSomeLogoBannersVisible.value.length > 0;
    });

    return {
      logoBannerBackgroundColor: computedLogoBannerBackgroundColor,
      areSomeLogoBannersVisible,
      computedAreSomeLogoBannersVisible,
      logoBannersOnPage,
    };
  },
);
