import { getMediaSrcSet } from "@/utils/media/getMediaSrcSet";
import type { Column, Section } from "@/types";
import { type MegaMenuSection } from "@/components/molecules/Header/Header.interface";
import { SectionTypes } from "@/types/extensions/mega-menu.interface";

export const mapMegaMenuTeaserColumn = (column: Column) => {
  const imageRow = column?.positions[0];
  const titleRow = column?.positions[1];
  const textRow = column?.positions[2];
  const mediaSrcSet = getMediaSrcSet(imageRow?.media);
  return {
    id: column?.id,
    title: titleRow?.content,
    text: textRow?.content,
    image: {
      src: imageRow?.media?.url,
      srcSet: mediaSrcSet,
      alt: imageRow?.media?.alt,
    },
    link: {
      link: imageRow?.link,
      newTab: imageRow?.linkNewTab,
    },
  };
};

export const mapMegaMenuLinksColumn = (column: Column) => {
  return column?.positions.map((position) => ({
    id: position?.id,
    title: position?.content,
    styles: position?.styles,
    link: {
      link: position?.link,
      newTab: position?.linkNewTab,
    },
  }));
};

export const mapMegaMenuSections = (sections: Section[]) => {
  return (
    sections?.map((section) => {
      const sectionLink = {
        link: section?.link,
        newTab: section?.linkNewTab,
      };
      return {
        id: section?.id,
        title: section?.name,
        link: sectionLink,
        type: section?.type,
        columns: section?.columns?.map((column) => {
          const imageCandidate = column?.positions[0];

          const isTeaserColumn = imageCandidate?.media?.url;
          if (isTeaserColumn) {
            return mapMegaMenuTeaserColumn(column);
          }

          return mapMegaMenuLinksColumn(column);
        }),
      };
    }) || []
  );
};

export const isLinkSection = (section: MegaMenuSection | null) => {
  return section?.type === SectionTypes.LINK;
};

export const isMegaMenuSection = (section: MegaMenuSection | null) => {
  return section?.type === SectionTypes.MEGA_MENU;
};
