import { defineStore } from "pinia";
import { ref } from "vue";

export const useFooterDetailStore = defineStore("footer-detail", () => {
  const shouldHideNewsletter = ref<boolean>(false);

  return {
    shouldHideNewsletter,
  };
});
