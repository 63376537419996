import { ref, computed } from "vue";
import { defineStore } from "pinia";
import { useSafeI18n } from "@/composables/useSafeI18n";
import { useRoute } from "vue-router";
import { benefitBarSnippetKeys } from "@/constants/snippetKeys";
import { useInternalLinkMapper } from "@/composables/useInternalLinkMapper";
import { usePageLoading } from "@/composables/usePageLoading";

export const useBenefitBarDetailsStore = defineStore(
  "benefit-bar-details",
  () => {
    const { isLoading } = usePageLoading();
    const route = useRoute();
    const { mapArrayWithHTMLLinks } = useInternalLinkMapper();

    const shouldBenefitBarBeDisplayed = computed(() => {
      if (isLoading.value) return false;
      if (!route?.fullPath) return false;
      const languages = ["de-DE", "en-GB"];
      let pathWithoutLocale = route.fullPath;
      languages.forEach((language) => {
        if (route.fullPath.includes(language)) {
          pathWithoutLocale = route.fullPath
            .replace(`/${language}/`, "/")
            .replace(`/${language}`, "/");
        }
      });

      const isOnHomePage =
        pathWithoutLocale === `/${route.hash}` ||
        pathWithoutLocale === route.hash;

      return !isOnHomePage;
    });

    const benefitBarHeight = ref<number>(0);

    const { t } = useSafeI18n();

    const filteredBenefitBarSnippets = ref(
      benefitBarSnippetKeys
        .filter((key) => {
          return t(key) !== key;
        })
        .map((key) => {
          return t(key) as string;
        }),
    );

    const setBenefitBarSnippetsWithSeoInternalLink = async () => {
      const mappedSnippets = await mapArrayWithHTMLLinks(
        filteredBenefitBarSnippets.value,
      );
      filteredBenefitBarSnippets.value = mappedSnippets;
    };

    setBenefitBarSnippetsWithSeoInternalLink();

    return {
      benefitBarHeight,
      texts: filteredBenefitBarSnippets,
      shouldBenefitBarBeDisplayed,
    };
  },
);
