import type { Schemas } from "#shopware";
import { usePrice as swUsePrice } from "@shopware-pwa/composables-next";

export const usePrice = () => {
  const _swUsePrice = swUsePrice();

  const getReadablePricePerUnitFromProduct = (product?: Schemas["Product"]) => {
    if (!product) {
      return;
    }
    if (
      !product.calculatedPrice?.referencePrice?.price ||
      !product.unit?.translated?.shortCode
    ) {
      return;
    }

    return `(${_swUsePrice.getFormattedPrice(
      product.calculatedPrice?.referencePrice?.price,
    )}/${product.unit?.translated?.shortCode})`;
  };

  return {
    ..._swUsePrice,
    getReadablePricePerUnitFromProduct,
  };
};
