export const resolveDayName = (day: number, locale: string = "de-DE") => {
  const baseDate = new Date(Date.UTC(2023, 0, 1)); // 2023-01-01 is a Sunday

  baseDate.setDate(baseDate.getDate() + day + 1);
  return baseDate.toLocaleString(locale, {
    weekday: "short",
  });
};

export const resolveMonthName = (month: number, locale: string = "de-DE") => {
  const date = new Date();
  date.setMonth(month);
  return date.toLocaleString(locale, {
    month: "long",
  });
};

export const resolveCalendarFormat = (date: Date | undefined) => {
  if (!date) return "";
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
};
