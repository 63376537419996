export const isSameDomain = (url: string) => {
  const config = useRuntimeConfig();

  const currentURLFromEnv = config.public.shopware.devStorefrontUrl;

  if (currentURLFromEnv) {
    if (url.toLowerCase().startsWith(currentURLFromEnv.toLowerCase())) {
      return true;
    }
  }
};
