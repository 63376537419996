import { defineStore } from "pinia";
import type { Schemas } from "#shopware";

export const useAccountStore = defineStore("account", () => {
  const paymentMethods = reactive<{
    methods: Schemas["PaymentMethod"][];
    selected: Schemas["PaymentMethod"] | null;
  }>({
    methods: [],
    selected: null,
  });

  return {
    paymentMethods,
  };
});
