import routerOptions0 from "/home/sawade/www/frontends/node_modules/nuxt/dist/pages/runtime/router.options";
const configRouterOptions = {
  linkActiveClass: "link-active",
  linkExactActiveClass: "link-exact-active text-primary",
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
}