import { default as _91_46_46_46all_93vUGi6jFlnmMeta } from "/home/sawade/www/frontends/pages/[...all].vue?macro=true";
import { default as addressVe7AlXi2gWMeta } from "/home/sawade/www/frontends/pages/account/address.vue?macro=true";
import { default as indexD8FM8w4lkGMeta } from "/home/sawade/www/frontends/pages/account/index.vue?macro=true";
import { default as newsletter3C7543x9WDMeta } from "/home/sawade/www/frontends/pages/account/newsletter.vue?macro=true";
import { default as orderD1kzw5e5LoMeta } from "/home/sawade/www/frontends/pages/account/order.vue?macro=true";
import { default as paymentnnGV8D6SJvMeta } from "/home/sawade/www/frontends/pages/account/payment.vue?macro=true";
import { default as profileKjlRxCVOL4Meta } from "/home/sawade/www/frontends/pages/account/profile.vue?macro=true";
import { default as indexsy2STgkqIyMeta } from "/home/sawade/www/frontends/pages/account/recover/index.vue?macro=true";
import { default as passwordnzqayAbmhPMeta } from "/home/sawade/www/frontends/pages/account/recover/password.vue?macro=true";
import { default as vouchersGZq5piIV0fMeta } from "/home/sawade/www/frontends/pages/account/vouchers.vue?macro=true";
import { default as cart5sb5nfC6XdMeta } from "/home/sawade/www/frontends/pages/checkout/cart.vue?macro=true";
import { default as indexUgioy5iJywMeta } from "/home/sawade/www/frontends/pages/checkout/index.vue?macro=true";
import { default as indexM05yeXIA9sMeta } from "/home/sawade/www/frontends/pages/checkout/success/[id]/index.vue?macro=true";
import { default as paid4anct0qoDEMeta } from "/home/sawade/www/frontends/pages/checkout/success/[id]/paid.vue?macro=true";
import { default as unpaidU9vb4nI60TMeta } from "/home/sawade/www/frontends/pages/checkout/success/[id]/unpaid.vue?macro=true";
import { default as loginjh7uj8cpseMeta } from "/home/sawade/www/frontends/pages/login.vue?macro=true";
import { default as newsletter_45subscribeDIVHGArfw2Meta } from "/home/sawade/www/frontends/pages/newsletter-subscribe.vue?macro=true";
import { default as finalize_45transactionfGBsp0h8eTMeta } from "/home/sawade/www/frontends/pages/payment/finalize-transaction.vue?macro=true";
import { default as registerIe2v5UXHFuMeta } from "/home/sawade/www/frontends/pages/register.vue?macro=true";
import { default as searchrBBlRZR5gEMeta } from "/home/sawade/www/frontends/pages/search.vue?macro=true";
import { default as shopware9R2yprxI6zMeta } from "/home/sawade/www/frontends/pages/shopware.vue?macro=true";
import { default as wishlistoToKv1oEfzMeta } from "/home/sawade/www/frontends/pages/wishlist.vue?macro=true";
export default [
  {
    name: _91_46_46_46all_93vUGi6jFlnmMeta?.name ?? "all___en-GB",
    path: _91_46_46_46all_93vUGi6jFlnmMeta?.path ?? "/en-GB/:all(.*)*",
    meta: _91_46_46_46all_93vUGi6jFlnmMeta || {},
    alias: _91_46_46_46all_93vUGi6jFlnmMeta?.alias || [],
    redirect: _91_46_46_46all_93vUGi6jFlnmMeta?.redirect,
    component: () => import("/home/sawade/www/frontends/pages/[...all].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46all_93vUGi6jFlnmMeta?.name ?? "all___de-DE",
    path: _91_46_46_46all_93vUGi6jFlnmMeta?.path ?? "/:all(.*)*",
    meta: _91_46_46_46all_93vUGi6jFlnmMeta || {},
    alias: _91_46_46_46all_93vUGi6jFlnmMeta?.alias || [],
    redirect: _91_46_46_46all_93vUGi6jFlnmMeta?.redirect,
    component: () => import("/home/sawade/www/frontends/pages/[...all].vue").then(m => m.default || m)
  },
  {
    name: addressVe7AlXi2gWMeta?.name ?? "account-address___en-GB",
    path: addressVe7AlXi2gWMeta?.path ?? "/en-GB/account/address",
    meta: addressVe7AlXi2gWMeta || {},
    alias: addressVe7AlXi2gWMeta?.alias || [],
    redirect: addressVe7AlXi2gWMeta?.redirect,
    component: () => import("/home/sawade/www/frontends/pages/account/address.vue").then(m => m.default || m)
  },
  {
    name: addressVe7AlXi2gWMeta?.name ?? "account-address___de-DE",
    path: addressVe7AlXi2gWMeta?.path ?? "/account/address",
    meta: addressVe7AlXi2gWMeta || {},
    alias: addressVe7AlXi2gWMeta?.alias || [],
    redirect: addressVe7AlXi2gWMeta?.redirect,
    component: () => import("/home/sawade/www/frontends/pages/account/address.vue").then(m => m.default || m)
  },
  {
    name: indexD8FM8w4lkGMeta?.name ?? "account___en-GB",
    path: indexD8FM8w4lkGMeta?.path ?? "/en-GB/account",
    meta: indexD8FM8w4lkGMeta || {},
    alias: indexD8FM8w4lkGMeta?.alias || [],
    redirect: indexD8FM8w4lkGMeta?.redirect,
    component: () => import("/home/sawade/www/frontends/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexD8FM8w4lkGMeta?.name ?? "account___de-DE",
    path: indexD8FM8w4lkGMeta?.path ?? "/account",
    meta: indexD8FM8w4lkGMeta || {},
    alias: indexD8FM8w4lkGMeta?.alias || [],
    redirect: indexD8FM8w4lkGMeta?.redirect,
    component: () => import("/home/sawade/www/frontends/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: newsletter3C7543x9WDMeta?.name ?? "account-newsletter___en-GB",
    path: newsletter3C7543x9WDMeta?.path ?? "/en-GB/account/newsletter",
    meta: newsletter3C7543x9WDMeta || {},
    alias: newsletter3C7543x9WDMeta?.alias || [],
    redirect: newsletter3C7543x9WDMeta?.redirect,
    component: () => import("/home/sawade/www/frontends/pages/account/newsletter.vue").then(m => m.default || m)
  },
  {
    name: newsletter3C7543x9WDMeta?.name ?? "account-newsletter___de-DE",
    path: newsletter3C7543x9WDMeta?.path ?? "/account/newsletter",
    meta: newsletter3C7543x9WDMeta || {},
    alias: newsletter3C7543x9WDMeta?.alias || [],
    redirect: newsletter3C7543x9WDMeta?.redirect,
    component: () => import("/home/sawade/www/frontends/pages/account/newsletter.vue").then(m => m.default || m)
  },
  {
    name: orderD1kzw5e5LoMeta?.name ?? "account-order___en-GB",
    path: orderD1kzw5e5LoMeta?.path ?? "/en-GB/account/order",
    meta: orderD1kzw5e5LoMeta || {},
    alias: orderD1kzw5e5LoMeta?.alias || [],
    redirect: orderD1kzw5e5LoMeta?.redirect,
    component: () => import("/home/sawade/www/frontends/pages/account/order.vue").then(m => m.default || m)
  },
  {
    name: orderD1kzw5e5LoMeta?.name ?? "account-order___de-DE",
    path: orderD1kzw5e5LoMeta?.path ?? "/account/order",
    meta: orderD1kzw5e5LoMeta || {},
    alias: orderD1kzw5e5LoMeta?.alias || [],
    redirect: orderD1kzw5e5LoMeta?.redirect,
    component: () => import("/home/sawade/www/frontends/pages/account/order.vue").then(m => m.default || m)
  },
  {
    name: paymentnnGV8D6SJvMeta?.name ?? "account-payment___en-GB",
    path: paymentnnGV8D6SJvMeta?.path ?? "/en-GB/account/payment",
    meta: paymentnnGV8D6SJvMeta || {},
    alias: paymentnnGV8D6SJvMeta?.alias || [],
    redirect: paymentnnGV8D6SJvMeta?.redirect,
    component: () => import("/home/sawade/www/frontends/pages/account/payment.vue").then(m => m.default || m)
  },
  {
    name: paymentnnGV8D6SJvMeta?.name ?? "account-payment___de-DE",
    path: paymentnnGV8D6SJvMeta?.path ?? "/account/payment",
    meta: paymentnnGV8D6SJvMeta || {},
    alias: paymentnnGV8D6SJvMeta?.alias || [],
    redirect: paymentnnGV8D6SJvMeta?.redirect,
    component: () => import("/home/sawade/www/frontends/pages/account/payment.vue").then(m => m.default || m)
  },
  {
    name: profileKjlRxCVOL4Meta?.name ?? "account-profile___en-GB",
    path: profileKjlRxCVOL4Meta?.path ?? "/en-GB/account/profile",
    meta: profileKjlRxCVOL4Meta || {},
    alias: profileKjlRxCVOL4Meta?.alias || [],
    redirect: profileKjlRxCVOL4Meta?.redirect,
    component: () => import("/home/sawade/www/frontends/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: profileKjlRxCVOL4Meta?.name ?? "account-profile___de-DE",
    path: profileKjlRxCVOL4Meta?.path ?? "/account/profile",
    meta: profileKjlRxCVOL4Meta || {},
    alias: profileKjlRxCVOL4Meta?.alias || [],
    redirect: profileKjlRxCVOL4Meta?.redirect,
    component: () => import("/home/sawade/www/frontends/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: indexsy2STgkqIyMeta?.name ?? "account-recover___en-GB",
    path: indexsy2STgkqIyMeta?.path ?? "/en-GB/account/recover",
    meta: indexsy2STgkqIyMeta || {},
    alias: indexsy2STgkqIyMeta?.alias || [],
    redirect: indexsy2STgkqIyMeta?.redirect,
    component: () => import("/home/sawade/www/frontends/pages/account/recover/index.vue").then(m => m.default || m)
  },
  {
    name: indexsy2STgkqIyMeta?.name ?? "account-recover___de-DE",
    path: indexsy2STgkqIyMeta?.path ?? "/account/recover",
    meta: indexsy2STgkqIyMeta || {},
    alias: indexsy2STgkqIyMeta?.alias || [],
    redirect: indexsy2STgkqIyMeta?.redirect,
    component: () => import("/home/sawade/www/frontends/pages/account/recover/index.vue").then(m => m.default || m)
  },
  {
    name: passwordnzqayAbmhPMeta?.name ?? "account-recover-password___en-GB",
    path: passwordnzqayAbmhPMeta?.path ?? "/en-GB/account/recover/password",
    meta: passwordnzqayAbmhPMeta || {},
    alias: passwordnzqayAbmhPMeta?.alias || [],
    redirect: passwordnzqayAbmhPMeta?.redirect,
    component: () => import("/home/sawade/www/frontends/pages/account/recover/password.vue").then(m => m.default || m)
  },
  {
    name: passwordnzqayAbmhPMeta?.name ?? "account-recover-password___de-DE",
    path: passwordnzqayAbmhPMeta?.path ?? "/account/recover/password",
    meta: passwordnzqayAbmhPMeta || {},
    alias: passwordnzqayAbmhPMeta?.alias || [],
    redirect: passwordnzqayAbmhPMeta?.redirect,
    component: () => import("/home/sawade/www/frontends/pages/account/recover/password.vue").then(m => m.default || m)
  },
  {
    name: vouchersGZq5piIV0fMeta?.name ?? "account-vouchers___en-GB",
    path: vouchersGZq5piIV0fMeta?.path ?? "/en-GB/account/vouchers",
    meta: vouchersGZq5piIV0fMeta || {},
    alias: vouchersGZq5piIV0fMeta?.alias || [],
    redirect: vouchersGZq5piIV0fMeta?.redirect,
    component: () => import("/home/sawade/www/frontends/pages/account/vouchers.vue").then(m => m.default || m)
  },
  {
    name: vouchersGZq5piIV0fMeta?.name ?? "account-vouchers___de-DE",
    path: vouchersGZq5piIV0fMeta?.path ?? "/account/vouchers",
    meta: vouchersGZq5piIV0fMeta || {},
    alias: vouchersGZq5piIV0fMeta?.alias || [],
    redirect: vouchersGZq5piIV0fMeta?.redirect,
    component: () => import("/home/sawade/www/frontends/pages/account/vouchers.vue").then(m => m.default || m)
  },
  {
    name: cart5sb5nfC6XdMeta?.name ?? "checkout-cart___en-GB",
    path: cart5sb5nfC6XdMeta?.path ?? "/en-GB/checkout/cart",
    meta: cart5sb5nfC6XdMeta || {},
    alias: cart5sb5nfC6XdMeta?.alias || [],
    redirect: cart5sb5nfC6XdMeta?.redirect,
    component: () => import("/home/sawade/www/frontends/pages/checkout/cart.vue").then(m => m.default || m)
  },
  {
    name: cart5sb5nfC6XdMeta?.name ?? "checkout-cart___de-DE",
    path: cart5sb5nfC6XdMeta?.path ?? "/checkout/cart",
    meta: cart5sb5nfC6XdMeta || {},
    alias: cart5sb5nfC6XdMeta?.alias || [],
    redirect: cart5sb5nfC6XdMeta?.redirect,
    component: () => import("/home/sawade/www/frontends/pages/checkout/cart.vue").then(m => m.default || m)
  },
  {
    name: indexUgioy5iJywMeta?.name ?? "checkout___en-GB",
    path: indexUgioy5iJywMeta?.path ?? "/en-GB/checkout",
    meta: indexUgioy5iJywMeta || {},
    alias: indexUgioy5iJywMeta?.alias || [],
    redirect: indexUgioy5iJywMeta?.redirect,
    component: () => import("/home/sawade/www/frontends/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexUgioy5iJywMeta?.name ?? "checkout___de-DE",
    path: indexUgioy5iJywMeta?.path ?? "/checkout",
    meta: indexUgioy5iJywMeta || {},
    alias: indexUgioy5iJywMeta?.alias || [],
    redirect: indexUgioy5iJywMeta?.redirect,
    component: () => import("/home/sawade/www/frontends/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexM05yeXIA9sMeta?.name ?? "checkout-success-id___en-GB",
    path: indexM05yeXIA9sMeta?.path ?? "/en-GB/checkout/success/:id()",
    meta: indexM05yeXIA9sMeta || {},
    alias: indexM05yeXIA9sMeta?.alias || [],
    redirect: indexM05yeXIA9sMeta?.redirect,
    component: () => import("/home/sawade/www/frontends/pages/checkout/success/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexM05yeXIA9sMeta?.name ?? "checkout-success-id___de-DE",
    path: indexM05yeXIA9sMeta?.path ?? "/checkout/success/:id()",
    meta: indexM05yeXIA9sMeta || {},
    alias: indexM05yeXIA9sMeta?.alias || [],
    redirect: indexM05yeXIA9sMeta?.redirect,
    component: () => import("/home/sawade/www/frontends/pages/checkout/success/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: paid4anct0qoDEMeta?.name ?? "checkout-success-id-paid___en-GB",
    path: paid4anct0qoDEMeta?.path ?? "/en-GB/checkout/success/:id()/paid",
    meta: paid4anct0qoDEMeta || {},
    alias: paid4anct0qoDEMeta?.alias || [],
    redirect: paid4anct0qoDEMeta?.redirect,
    component: () => import("/home/sawade/www/frontends/pages/checkout/success/[id]/paid.vue").then(m => m.default || m)
  },
  {
    name: paid4anct0qoDEMeta?.name ?? "checkout-success-id-paid___de-DE",
    path: paid4anct0qoDEMeta?.path ?? "/checkout/success/:id()/paid",
    meta: paid4anct0qoDEMeta || {},
    alias: paid4anct0qoDEMeta?.alias || [],
    redirect: paid4anct0qoDEMeta?.redirect,
    component: () => import("/home/sawade/www/frontends/pages/checkout/success/[id]/paid.vue").then(m => m.default || m)
  },
  {
    name: unpaidU9vb4nI60TMeta?.name ?? "checkout-success-id-unpaid___en-GB",
    path: unpaidU9vb4nI60TMeta?.path ?? "/en-GB/checkout/success/:id()/unpaid",
    meta: unpaidU9vb4nI60TMeta || {},
    alias: unpaidU9vb4nI60TMeta?.alias || [],
    redirect: unpaidU9vb4nI60TMeta?.redirect,
    component: () => import("/home/sawade/www/frontends/pages/checkout/success/[id]/unpaid.vue").then(m => m.default || m)
  },
  {
    name: unpaidU9vb4nI60TMeta?.name ?? "checkout-success-id-unpaid___de-DE",
    path: unpaidU9vb4nI60TMeta?.path ?? "/checkout/success/:id()/unpaid",
    meta: unpaidU9vb4nI60TMeta || {},
    alias: unpaidU9vb4nI60TMeta?.alias || [],
    redirect: unpaidU9vb4nI60TMeta?.redirect,
    component: () => import("/home/sawade/www/frontends/pages/checkout/success/[id]/unpaid.vue").then(m => m.default || m)
  },
  {
    name: loginjh7uj8cpseMeta?.name ?? "login___en-GB",
    path: loginjh7uj8cpseMeta?.path ?? "/en-GB/login",
    meta: loginjh7uj8cpseMeta || {},
    alias: loginjh7uj8cpseMeta?.alias || [],
    redirect: loginjh7uj8cpseMeta?.redirect,
    component: () => import("/home/sawade/www/frontends/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginjh7uj8cpseMeta?.name ?? "login___de-DE",
    path: loginjh7uj8cpseMeta?.path ?? "/login",
    meta: loginjh7uj8cpseMeta || {},
    alias: loginjh7uj8cpseMeta?.alias || [],
    redirect: loginjh7uj8cpseMeta?.redirect,
    component: () => import("/home/sawade/www/frontends/pages/login.vue").then(m => m.default || m)
  },
  {
    name: newsletter_45subscribeDIVHGArfw2Meta?.name ?? "newsletter-subscribe___en-GB",
    path: newsletter_45subscribeDIVHGArfw2Meta?.path ?? "/en-GB/newsletter-subscribe",
    meta: newsletter_45subscribeDIVHGArfw2Meta || {},
    alias: newsletter_45subscribeDIVHGArfw2Meta?.alias || [],
    redirect: newsletter_45subscribeDIVHGArfw2Meta?.redirect,
    component: () => import("/home/sawade/www/frontends/pages/newsletter-subscribe.vue").then(m => m.default || m)
  },
  {
    name: newsletter_45subscribeDIVHGArfw2Meta?.name ?? "newsletter-subscribe___de-DE",
    path: newsletter_45subscribeDIVHGArfw2Meta?.path ?? "/newsletter-subscribe",
    meta: newsletter_45subscribeDIVHGArfw2Meta || {},
    alias: newsletter_45subscribeDIVHGArfw2Meta?.alias || [],
    redirect: newsletter_45subscribeDIVHGArfw2Meta?.redirect,
    component: () => import("/home/sawade/www/frontends/pages/newsletter-subscribe.vue").then(m => m.default || m)
  },
  {
    name: finalize_45transactionfGBsp0h8eTMeta?.name ?? "payment-finalize-transaction___en-GB",
    path: finalize_45transactionfGBsp0h8eTMeta?.path ?? "/en-GB/payment/finalize-transaction",
    meta: finalize_45transactionfGBsp0h8eTMeta || {},
    alias: finalize_45transactionfGBsp0h8eTMeta?.alias || [],
    redirect: finalize_45transactionfGBsp0h8eTMeta?.redirect,
    component: () => import("/home/sawade/www/frontends/pages/payment/finalize-transaction.vue").then(m => m.default || m)
  },
  {
    name: finalize_45transactionfGBsp0h8eTMeta?.name ?? "payment-finalize-transaction___de-DE",
    path: finalize_45transactionfGBsp0h8eTMeta?.path ?? "/payment/finalize-transaction",
    meta: finalize_45transactionfGBsp0h8eTMeta || {},
    alias: finalize_45transactionfGBsp0h8eTMeta?.alias || [],
    redirect: finalize_45transactionfGBsp0h8eTMeta?.redirect,
    component: () => import("/home/sawade/www/frontends/pages/payment/finalize-transaction.vue").then(m => m.default || m)
  },
  {
    name: registerIe2v5UXHFuMeta?.name ?? "register___en-GB",
    path: registerIe2v5UXHFuMeta?.path ?? "/en-GB/register",
    meta: registerIe2v5UXHFuMeta || {},
    alias: registerIe2v5UXHFuMeta?.alias || [],
    redirect: registerIe2v5UXHFuMeta?.redirect,
    component: () => import("/home/sawade/www/frontends/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerIe2v5UXHFuMeta?.name ?? "register___de-DE",
    path: registerIe2v5UXHFuMeta?.path ?? "/register",
    meta: registerIe2v5UXHFuMeta || {},
    alias: registerIe2v5UXHFuMeta?.alias || [],
    redirect: registerIe2v5UXHFuMeta?.redirect,
    component: () => import("/home/sawade/www/frontends/pages/register.vue").then(m => m.default || m)
  },
  {
    name: searchrBBlRZR5gEMeta?.name ?? "search___en-GB",
    path: searchrBBlRZR5gEMeta?.path ?? "/en-GB/search",
    meta: searchrBBlRZR5gEMeta || {},
    alias: searchrBBlRZR5gEMeta?.alias || [],
    redirect: searchrBBlRZR5gEMeta?.redirect,
    component: () => import("/home/sawade/www/frontends/pages/search.vue").then(m => m.default || m)
  },
  {
    name: searchrBBlRZR5gEMeta?.name ?? "search___de-DE",
    path: searchrBBlRZR5gEMeta?.path ?? "/search",
    meta: searchrBBlRZR5gEMeta || {},
    alias: searchrBBlRZR5gEMeta?.alias || [],
    redirect: searchrBBlRZR5gEMeta?.redirect,
    component: () => import("/home/sawade/www/frontends/pages/search.vue").then(m => m.default || m)
  },
  {
    name: shopware9R2yprxI6zMeta?.name ?? "shopware___en-GB",
    path: shopware9R2yprxI6zMeta?.path ?? "/en-GB/shopware",
    meta: shopware9R2yprxI6zMeta || {},
    alias: shopware9R2yprxI6zMeta?.alias || [],
    redirect: shopware9R2yprxI6zMeta?.redirect,
    component: () => import("/home/sawade/www/frontends/pages/shopware.vue").then(m => m.default || m)
  },
  {
    name: shopware9R2yprxI6zMeta?.name ?? "shopware___de-DE",
    path: shopware9R2yprxI6zMeta?.path ?? "/shopware",
    meta: shopware9R2yprxI6zMeta || {},
    alias: shopware9R2yprxI6zMeta?.alias || [],
    redirect: shopware9R2yprxI6zMeta?.redirect,
    component: () => import("/home/sawade/www/frontends/pages/shopware.vue").then(m => m.default || m)
  },
  {
    name: wishlistoToKv1oEfzMeta?.name ?? "wishlist___en-GB",
    path: wishlistoToKv1oEfzMeta?.path ?? "/en-GB/wishlist",
    meta: wishlistoToKv1oEfzMeta || {},
    alias: wishlistoToKv1oEfzMeta?.alias || [],
    redirect: wishlistoToKv1oEfzMeta?.redirect,
    component: () => import("/home/sawade/www/frontends/pages/wishlist.vue").then(m => m.default || m)
  },
  {
    name: wishlistoToKv1oEfzMeta?.name ?? "wishlist___de-DE",
    path: wishlistoToKv1oEfzMeta?.path ?? "/wishlist",
    meta: wishlistoToKv1oEfzMeta || {},
    alias: wishlistoToKv1oEfzMeta?.alias || [],
    redirect: wishlistoToKv1oEfzMeta?.redirect,
    component: () => import("/home/sawade/www/frontends/pages/wishlist.vue").then(m => m.default || m)
  }
]